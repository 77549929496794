<template>
  <div>
    <van-nav-bar title="我的拓客厂" left-arrow :fixed="true" :z-index="999" @click-left="back" />

    <div style="padding-top:46px;">
      <van-cell-group>
        <van-cell v-for="(item,idx) in list" :key="idx" :value="item.garageName" :to="{name: 'tuoke_income', params: { gid: item.gid, garageName: item.garageName }}" />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import userwei from "../../api/user";

export default {
  components: {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      list: []
    };
  },

  methods: {
    // this.userInfo.userId
    onLoad() {
      userwei.garageList({ customerId: this.userInfo.id }).then(res => {
        console.log(res);
        this.list = res.data;
      });
    },
    back() {
      this.$router.go(-1);
      //   location.replace('user')
      //  this.$router.push({
      //   name: 'user'
      // })
    }
  },
  created() {
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
.views {
  /deep/ div:nth-of-type(2) span {
    color: #1989fa;
  }
}
.van-cell {
  color: #323233;
  font-size: 13px;
}
</style>
